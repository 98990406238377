import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getBlogsService = serviceBase({
	request: () => axiosClient.get('blog')
});
export const getEventsService = serviceBase({
	request: () => axiosClient.get('event')
});

export default getBlogsService;