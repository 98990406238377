import { BlogsActionCreators } from '../reducers/blogReducer';
import {
	getBlogsService,
	getEventsService
} from '../services/blogs';

export const getBlogs = data => async dispatch => {
	dispatch(BlogsActionCreators.requestBlogs());
	try {
		const response = await getBlogsService();

		dispatch(
			BlogsActionCreators.successfulBlogs({
				result: response
			})
		);
	} catch (e) {
		dispatch(BlogsActionCreators.errorBlogs(e));
	}
};

export const getEvents = data => async dispatch => {
	dispatch(BlogsActionCreators.requestEvents());
	try {
		const response = await getEventsService();

		dispatch(
			BlogsActionCreators.successfulEvents({
				result: response
			})
		);
	} catch (e) {
		dispatch(BlogsActionCreators.errorEvents(e));
	}
};