import uuid from "uuid/v4";
import { checkIfVariantsMissing, checkIfHasVariants } from "../utils/cart";
import _ from "lodash";
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART,
  OPEN_MENU_CART,
} from "../actions/cartActions";

const initState = [];

const isArrayEqual = (x, y) => {
  return _(x).differenceWith(y, _.isEqual).isEmpty();
};

const cartItemExists = (
  item,
  product,
  colorSelected,
  designSelected,
  sizeSelected,
  optionNeutralSelected
) => {
  const { selectedVariants } = item;

  return (
    item._id === product._id &&
    selectedVariants.colorSelected.value === colorSelected.value &&
    selectedVariants.sizeSelected.value === sizeSelected.value &&
    selectedVariants.designSelected.value === designSelected.value &&
    selectedVariants.optionNeutralSelected.value === optionNeutralSelected.value
  );
};

const cartReducer = (state = initState, action) => {
  const cartItems = state;
  const product = action.payload;

  if (action.type === ADD_TO_CART) {
    // for non variant products
    const { selectedVariants } = product;
    if (!checkIfHasVariants(product)) {
      const cartItem = cartItems.filter((item) => item._id === product._id)[0];
      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid(),
          },
        ];
      }
      return cartItems.map((item) =>
        item.cartItemId === cartItem.cartItemId
          ? {
            ...item,
            quantity: product.quantity
              ? item.quantity + product.quantity
              : item.quantity + 1,
          }
          : item
      );

      // for variant products
    }
    const {
      colorSelected,
      designSelected,
      sizeSelected,
      optionNeutralSelected,
    } = selectedVariants;
    const cartItem = cartItems.filter((item) => {
      return cartItemExists(
        item,
        product,
        colorSelected,
        designSelected,
        sizeSelected,
        optionNeutralSelected
      );
    })[0];

    if (cartItem === undefined) {
      return [
        ...cartItems,
        {
          ...product,
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: uuid(),
        },
      ];
    }

    return cartItems.map((item) => {
      return cartItemExists(
        item,
        cartItem,
        colorSelected,
        designSelected,
        sizeSelected,
        optionNeutralSelected
      )
        ? {
          ...item,
          quantity: product.quantity
            ? item.quantity + product.quantity
            : item.quantity + 1,
        }
        : item;
    });
  }

  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          (cartItem) => cartItem.cartItemId !== product.cartItemId
        );
      return remainingItems(cartItems, product);
    }
    return cartItems.map((item) =>
      item.cartItemId === product.cartItemId
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(
        (cartItem) => cartItem.cartItemId !== product.cartItemId
      );
    return remainingItems(cartItems, product);
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter((item) => false);
  }
  return state;
};

export default cartReducer;
